<template>
  <transition-group tag="div" class="notifications" name="slide-fade">
    <NotificationCustom
      v-for="notification in notifications"
      :key="notification.id"
      :message="notification.message"
      :action="notification.action && notification.action.text"
      :type="notification.type"
      visible
      @click:close="notification.dismiss"
      @click:action="
        (e) =>
          notification.action &&
          notification.action.onClick.call(null, e, notification)
      "
    >
      <template v-if="notification.icon" #icon>
        <SfIcon :icon="notification.icon" color="black" />
      </template>
    </NotificationCustom>
  </transition-group>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import NotificationCustom from '~/components/NotificationCustom.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    SfIcon,
    NotificationCustom,
  },
  setup() {
    const { notifications } = useUiNotification();

    return {
      notifications,
    };
  },
});
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  @include to-portrait-max {
    z-index: 999999999999;
  }
  @include for-desktop {
    left: 50%;
    transform: translateX(-50%);

    top: 35%;
    height: 0;
    width: 400px;
    z-index: 999999999999;
  }
}

.sf-notification {
  max-width: 100%;
  margin: var(--spacer-xs) auto 0 auto;
  --notification-background: var(--c-white);
  --notification-color: var(--c-black);

  &:first-child {
    margin-top: 0;
  }

  @include for-mobile {
    --notification-border-radius: 0;
    --notification-max-width: 100%;
    --notification-font-size: var(--font-size--sm);
    --notification-font-family: var(--font-family--primary);
    --notification-font-weight: var(--font-weight--normal);
    --notification-padding: var(--spacer-base) var(--spacer-lg);
  }
  @include for-desktop {
    margin: 0 0 var(--spacer-xs) 0;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1.2s;
}

.slide-fade-enter {
  transform: translateY(40px);
  @include for-desktop {
    transform: translateY(-40px);
    opacity: 0;
  }
}

.slide-fade-leave-to {
  transform: translateY(80px);
  @include for-desktop {
    transform: translateY(-80px);
    opacity: 0;
  }
}
</style>

<style lang="scss">
.notifications {
  .sf-notification {
    &__close {
      right: var(--space-s);
      width: fit-content;
    }
    &__message {
      @include font-20x27-500;
    }
  }
}
</style>
